<template>
  <div
    class="flex flex-col flex-wrap items-center justify-end gap-x-2.5 gap-y-1 text-xs"
  >
    <p
      :style="paragraphStyles"
      class="break-words text-center font-medium text-primary"
    >
      <Price
        v-if="typeof props.individualCustomerSinglePackagePrice === 'number'"
        :value="props.individualCustomerSinglePackagePrice"
      />
    </p>

    <p
      v-if="
        !isNetPriceEqualNetPriceWithDiscount(
          props.individualCustomerSinglePackagePrice,
          props.regularPrice,
          props.finalPrice,
        )
      "
      :style="paragraphStyles"
      class="break-words text-center text-grey"
    >
      <Price
        v-if="props.regularPrice"
        :value="props.regularPrice"
        line-through
      />
    </p>
  </div>
</template>

<script setup lang="ts">
import { isNetPriceEqualNetPriceWithDiscount } from '@ecom/composables/checkout/utils'
import Price from '@ecom/components/Price/Price.vue'
import type { M2ProductItemNetPriceForPackageProps } from './M2ProductItemNetPriceForPackage.types'

const props = defineProps<M2ProductItemNetPriceForPackageProps>()

const paragraphStyles = {
  fontSize: 'inherit',
  lineHeight: 'inherit',
  maxWidth: '11ch',
}
</script>
